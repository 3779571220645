// types
import type { Dispatch, SetStateAction } from 'react';
import type { TilesPayload } from '../../content-elements-payload';

// components
import { CmsLink } from 'components/Link/CmsLink';
import { Image } from 'components/image/image';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { clickTileLink } from '../trackingActions';
import { useIsCC } from 'utils/hooks/use-is-cc';
import { decodingContent } from 'utils/decodingContent';
import { classNameBuilder } from 'utils/classNameBuilder';
import { isEmpty } from 'utils/is-empty';
import { useTracking as useTracking2 } from 'utils/tracking/track';

type TileLinkProps = {
  setMouseUpX: Dispatch<SetStateAction<null | number>>;
  setMouseDownX: Dispatch<SetStateAction<null | number>>;
  onMouseDown: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseUp: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  index: number;
  carousel: boolean;
  tile: NonNullable<TilesPayload['tiles']>[number];
  isChild: boolean;
  mouseUpX: number | null;
  mouseDownX: number | null;
  isSectionReference: boolean;
};

function TileLink({
  setMouseUpX,
  setMouseDownX,
  onMouseDown,
  onMouseUp,
  index,
  carousel,
  tile: { link, pictureAlt, description, content, title, pictureObject, picture, isAutofilled },
  isChild,
  mouseUpX = null,
  mouseDownX = null,
  isSectionReference = false,
}: Readonly<TileLinkProps>) {
  const isCC = useIsCC();
  const track = useTracking();
  const { trackClick } = useTracking2();

  const onClick = (e) => {
    if (mouseDownX !== mouseUpX) {
      e.preventDefault();
      setMouseUpX(null);
      setMouseDownX(null);
    } else if (title && mouseDownX === mouseUpX) {
      track.trackEvent(clickTileLink(title));

      trackClick({
        click_intent: 'more_info',
        click_element: 'tile',
        click_text: title,
      });
    }
  };

  return (
    <div
      className={classNameBuilder(
        'c-tiles__column small-12 medium-6 large-4 cell',
        carousel && ' c-tiles--carousel',
      )}
    >
      <InlineEdit previewId={`#${index}`} isSectionReference={isSectionReference}>
        <div
          className={classNameBuilder(
            'c-tiles__wrapper',
            !isEmpty(link) && 'c-tiles__wrapper--linked',
          )}
        >
          <CmsLink
            link={isAutofilled && isCC ? { ...link, window: '_self' } : link}
            className="c-tiles__link"
            tracking={onClick}
            // prevent link click on swipe
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
          >
            {isAutofilled && isCC && <div className="c-tiles--autofilled" />}
            <Image
              className="c-tiles__image"
              data={pictureObject || picture}
              alt={pictureAlt}
              title={description || pictureAlt || ''}
              isSectionReference={isSectionReference}
            />
            <div className="c-tiles__content">
              <InnerHtml
                as={isChild ? 'h5' : 'h3'}
                className="c-tiles__headline"
                content={title}
                previewId={isSectionReference || isAutofilled ? undefined : '#st_headline'}
              />
              <InnerHtml
                as="div"
                content={content}
                previewId={isSectionReference || isAutofilled ? undefined : '#st_content'}
              />
              {link && (
                <span className={classNameBuilder('link', link.showArrow && 'link-arrow')}>
                  {decodingContent(link.text)}
                </span>
              )}
            </div>
          </CmsLink>
        </div>
      </InlineEdit>
    </div>
  );
}

export default TileLink;
