import { Dispatch, SetStateAction, useState } from 'react';

// types
import type { TilesPayload } from 'components/ContentElements/content-elements-payload';

// components
import VideoLightbox from 'components/lightbox/video-lightbox';
import { Image } from 'components/image/image';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { CmsLink } from 'components/Link/CmsLink';
import VideoPlayButton from 'components/Video/VideoPlayButton';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { useUCConsent, VideoConsent } from 'features/cookie-consent';
import { useIsMobile } from 'components/App/SizeProvider';
import { useTracking } from 'utils/hooks/useTracking';
import { clickTileLink } from '../trackingActions';
import { classNameBuilder } from 'utils/classNameBuilder';
import { emptyStringFallback } from 'utils/is-empty';

type TileVideoProps = {
  setMouseUpX: Dispatch<SetStateAction<null | number>>;
  setMouseDownX: Dispatch<SetStateAction<null | number>>;
  onMouseDown: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseUp: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  index: number;
  carousel: boolean;
  tile: NonNullable<TilesPayload['tiles']>[number];
  isChild: boolean;
  mouseUpX: number | null;
  mouseDownX: number | null;
  isSectionReference: boolean;
};

function TileVideo({
  setMouseUpX,
  setMouseDownX,
  onMouseDown,
  onMouseUp,
  index,
  carousel,
  tile: { picture, pictureObject, link, pictureAlt, description, content, title, video },
  isChild,
  mouseUpX = null,
  mouseDownX = null,
  isSectionReference = false,
}: Readonly<TileVideoProps>) {
  const videoPreviewImage = video.videoPreviewImage;
  const { desktop, mobile } = videoPreviewImage || {};
  const isMobile = useIsMobile();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const track = useTracking();
  const ucConsent = useUCConsent();
  const isYoutube = video.type === 'youtube';

  const handleOpen = (e) => {
    e.preventDefault();

    if (mouseDownX !== mouseUpX) {
      setMouseUpX(null);
      setMouseDownX(null);
    } else if (title && mouseDownX === mouseUpX) {
      track.trackEvent(clickTileLink(title));
      setModalIsOpen(true);
    }
  };

  const previewImageBreakpointChange = () => {
    const imageSrc = isMobile ? mobile : emptyStringFallback(desktop, mobile);
    const fallbackImage = '/images/dummy-image-1x1.jpg';

    return (
      <Image
        src={imageSrc || fallbackImage}
        alt={pictureAlt}
        title={pictureAlt}
        format="16_9"
        className="c-tiles__image"
      />
    );
  };

  const tilePicture: PictureObject = pictureObject || picture;

  return (
    <div
      className={`c-tiles__column small-12 medium-6 large-4 cell c-tiles__link ${
        carousel ? ' c-tiles--carousel' : ''
      }`}
    >
      <InlineEdit previewId={isSectionReference ? undefined : `#${index}`}>
        <div className="c-tiles__wrapper c-tiles__wrapper--video">
          {ucConsent.Comfort === true && (
            <a
              href="#openVideo"
              onClick={handleOpen}
              className="c-tiles__link" // prevent link click on swipe
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
            >
              <VideoPlayButton />
              {isYoutube ? (
                <Image
                  className="c-tiles__image"
                  data={tilePicture}
                  alt={pictureAlt}
                  title={description ?? pictureAlt ?? ''}
                />
              ) : (
                video.videoId && previewImageBreakpointChange()
              )}
            </a>
          )}
          {ucConsent.Comfort === false && (
            <div className="c-tiles__link">
              {isYoutube ? <VideoConsent type="youtube" /> : <VideoConsent type="mi24" />}
            </div>
          )}
          <div className="c-tiles__content">
            <InnerHtml
              as={isChild ? 'h5' : 'h3'}
              content={title}
              previewId={isSectionReference ? undefined : '#st_headline'}
              className={classNameBuilder('c-tiles__headline')}
            />
            <InnerHtml
              as="div"
              content={content}
              previewId={isSectionReference ? undefined : '#st_content'}
            />
            {!!link && !carousel && <CmsLink link={link} />}
          </div>
        </div>
      </InlineEdit>
      {modalIsOpen && (
        <VideoLightbox
          isModal
          videoId={video.videoId}
          isYoutubeVideo={isYoutube}
          title={title}
          onClose={() => setModalIsOpen(false)}
        />
      )}
    </div>
  );
}

export default TileVideo;
